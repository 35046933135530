import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { stripHtml } from 'client/utils/string-utils';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';

import { CREATIVE_ID, LG_BG_SLIDES, MD_BG_SLIDES, MOBILE_BG_SLIDES } from './constants';
import { VehicleShowcaseSlideContent } from './vehicle-showcase-slide-content';
import { VehicleShowcaseSlidePresentation } from './vehicle-showcase-slide-presentation';
import './vehicle-showcase-slide.scss';

export function VehicleShowcaseSlide({
  slideContent,
  slideContentChildren,
  isMobile,
  isActive,
  videoAutoPlay,
  slideIndex,
  slideId,
  isLazyImages,
  isMediumLayout,
  useContainer,
}) {
  const hasSubNav = !!slideContentChildren.length;
  const focusRef = useRef();
  const isMounted = useRef(false);

  const [selectedSubNavIndex, setSelectedSubNavIndex] = useState(hasSubNav ? 0 : -1);

  function navClick({ target }) {
    const newIndex = parseInt(target.value, 10);

    EventToolbox.fireTrackAction({
      ads: {
        disable_refresh: true,
      },
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        action_name: TrackingConstant.ACTION_TYPE_SELECT,
        subaction_name: 'hvs_subtab_select',
        creative_id: CREATIVE_ID,
        value: slideContentChildren[newIndex].subTabName,
        position: newIndex + 1,
      },
    });
    setSelectedSubNavIndex(newIndex);
  }

  useEffect(() => {
    if (!isMobile && isMounted.current && isActive) {
      focusRef.current.focus();
    }
  }, [isActive, isMobile]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const { sponsoredContentLabel, headline, oemUrl } = slideContent;
  const tabIndex = isActive ? 0 : -1;

  const titleComponent = (
    <Fragment>
      <div className="medium fw-bold text-cool-gray-50">{sponsoredContentLabel}&nbsp;</div>
      <h2
        className="heading-3 fw-bold mb-1 mb-md-1_25"
        tabIndex={tabIndex} /* eslint-disable-line jsx-a11y/no-noninteractive-tabindex */
        ref={focusRef}
      >
        <ContentFragment componentToUse="span">{headline}</ContentFragment>
      </h2>
    </Fragment>
  );

  const cssProps = {};
  cssProps['--sub-tab-length'] = slideContentChildren.length;

  return (
    <div
      className={classnames(
        'vehicle-showcase-slide pos-r',
        {
          'fade-in': !isMobile && isActive,
          'fade-out': !isMobile && !isActive,
          'w-slide px-2 pb-3_5 pt-lg-2': !isMobile,
          'w-100 px-1': isMobile,
        },
        `index-${slideIndex}`
      )}
      role="tabpanel"
      aria-hidden={!isActive}
      aria-labelledby={`tab-${slideId}`}
      id={`tabpanel-${slideId}`}
    >
      {!isMediumLayout && (
        <Fragment>
          <ClippedContainer
            mask={LG_BG_SLIDES[slideIndex]}
            containerClasses={classnames('hidden-lg-down pos-a left-0 w-100 bottom-0', `bg-lg-${slideIndex}`)}
          />
          <ClippedContainer
            mask={MD_BG_SLIDES[slideIndex]}
            containerClasses={classnames(
              'hidden-md-down hidden-lg-up pos-a left-0 w-100 bottom-0',
              `bg-md-${slideIndex}`
            )}
          />
        </Fragment>
      )}
      {isMediumLayout && (
        <ClippedContainer
          mask={MD_BG_SLIDES[slideIndex]}
          containerClasses={classnames('hidden-md-down pos-a left-0 w-100 bottom-0', `bg-md-${slideIndex}`)}
        />
      )}
      <ClippedContainer
        mask={MOBILE_BG_SLIDES[slideIndex]}
        containerClasses={classnames('hidden-md-up pos-a left-0 w-100 bottom-0', `bg-sm-${slideIndex}`)}
      />
      <div className={classnames('h-100 d-flex flex-column', { container: useContainer })}>
        <div className="hidden-md-down hidden-lg-up">{titleComponent}</div>
        <div className="h-100">
          <Row className="h-100 slide-content flex-column flex-md-row flex-nowrap">
            <Col md={6} className="h-100 p-md-1_25 p-lg-2 pb-1 pb-md-1_25 d-flex flex-column flex-shrink-1">
              <div className="hidden-lg-down">{titleComponent}</div>
              {hasSubNav && (
                <Fragment>
                  <Row className="mb-1_25 mx-0" aria-label={`${stripHtml(headline)} slides`} role="tablist">
                    {slideContentChildren.map(({ subTabName }, subNavIndex) => {
                      const isTabActive = selectedSubNavIndex === subNavIndex;
                      const tabId = makeNiceName(subTabName);
                      return (
                        <Col
                          xs={4}
                          tag="button"
                          key={tabId}
                          className={classnames('nav-btn medium fw-medium text-gray-darker px-0_5 py-0_25', {
                            active: isTabActive,
                          })}
                          value={subNavIndex}
                          tabIndex={tabIndex}
                          onClick={navClick}
                          role="tab"
                          aria-selected={isTabActive}
                          aria-controls={`tabpanel-${slideId}-${tabId}`}
                        >
                          {subTabName}
                        </Col>
                      );
                    })}
                  </Row>
                  <div aria-live="polite" className="h-100 overflow-hidden">
                    <div
                      style={cssProps}
                      className={classnames('h-100', {
                        'd-flex w-all-sub-slides': hasSubNav,
                      })}
                    >
                      {slideContentChildren.map((item, subNavIndex) => {
                        const tabId = makeNiceName(item.subTabName);
                        return (
                          <VehicleShowcaseSlideContent
                            key={tabId}
                            {...item}
                            headline={headline}
                            oemUrl={oemUrl}
                            isMobile={isMobile}
                            isActive={selectedSubNavIndex === subNavIndex && isActive}
                            position={`${slideIndex + 1}-${subNavIndex + 1}`}
                            className={classnames(`pos-r w-sub-slide index-${subNavIndex}`, {
                              'fade-in': selectedSubNavIndex === subNavIndex && isActive,
                              'fade-out': selectedSubNavIndex !== subNavIndex,
                            })}
                            style={{ flexShrink: 0 }}
                            tabId={`${slideId}-${tabId}`}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Fragment>
              )}
              {!hasSubNav && (
                <VehicleShowcaseSlideContent
                  {...slideContent}
                  isMobile={isMobile}
                  isActive={isActive}
                  position={`${slideIndex + 1}`}
                />
              )}
            </Col>
            <div
              aria-live="polite"
              className={classnames(
                'col-md-6 img-content overflow-hidden mb-1 mb-md-0 px-0 d-md-flex align-items-center order-first order-md-last'
              )}
            >
              {hasSubNav && (
                <div className="w-100">
                  <div className="d-flex w-all-sub-slides">
                    {slideContentChildren.map((item, subNavIndex) => (
                      <div
                        key={item.subTabName}
                        className={classnames(`ps-0_5 ps-md-2 pe-0_5 w-100 pos-r w-sub-slide index-${subNavIndex}`, {
                          'fade-in': selectedSubNavIndex === subNavIndex && isActive,
                          'fade-out': selectedSubNavIndex !== subNavIndex,
                        })}
                      >
                        <VehicleShowcaseSlidePresentation
                          slide={item}
                          headline={headline}
                          tabIndex={tabIndex}
                          isLazyImages={isLazyImages}
                          isActive={selectedSubNavIndex === subNavIndex && isActive}
                          videoAutoPlay={videoAutoPlay}
                          trackingPosition={[slideIndex + 1, selectedSubNavIndex + 1].join('-')}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!hasSubNav && (
                <VehicleShowcaseSlidePresentation
                  slide={slideContent}
                  headline={headline}
                  tabIndex={tabIndex}
                  isLazyImages={isLazyImages}
                  isActive={isActive}
                  videoAutoPlay={videoAutoPlay}
                  trackingPosition={`${slideIndex + 1}`}
                />
              )}
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}

VehicleShowcaseSlide.propTypes = {
  slideContent: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    animationImages: PropTypes.string,
    copyText: PropTypes.string,
    ctaText: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    ctaUrlMobile: PropTypes.string.isRequired,
    detailsText: PropTypes.string,
    detailsUrl: PropTypes.string,
    detailsUrlMobile: PropTypes.string,
    ctaTargetNew: PropTypes.string,
    oemUrl: PropTypes.string,
  }),
  slideContentChildren: PropTypes.arrayOf(
    PropTypes.shape({
      subTabName: PropTypes.string,
      image: PropTypes.string,
      animationImages: PropTypes.string,
      copyText: PropTypes.string,
      ctaText: PropTypes.string,
      ctaUrl: PropTypes.string,
      ctaUrlMobile: PropTypes.string,
      detailsText: PropTypes.string,
      detailsUrl: PropTypes.string,
      detailsUrlMobile: PropTypes.string,
      ctaTargetNew: PropTypes.string,
    })
  ),
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool,
  isLazyImages: PropTypes.bool,
  slideIndex: PropTypes.number,
  slideId: PropTypes.string.isRequired,
  isMediumLayout: PropTypes.bool,
  useContainer: PropTypes.bool,
  videoAutoPlay: PropTypes.bool,
};

VehicleShowcaseSlide.defaultProps = {
  slideContent: {},
  slideContentChildren: [],
  isActive: false,
  isLazyImages: false,
  slideIndex: 0,
  isMobile: false,
  isMediumLayout: false,
  useContainer: false,
  videoAutoPlay: true,
};
